import type { PropsWithChildren, ReactNode } from 'react';
import { useState } from 'react';
import { useSegment } from '@repo/poly-hooks/segment';
import { isUnsupportedIOS, isUnsupportedAndroid } from '#src/utils/fourthline';
import { simulateLinkClick } from '#src/utils/simulate-link-click';
import { Unsupported } from '#src/components/fourthline/unsupported';
import { Pending } from '#src/components/fourthline/pending';
import { LinkToMobile } from '#src/components/fourthline/link-to-mobile';
import { Reassurance } from '#src/components/fourthline/reassurance';
import type { EmbeddedWebsdkProps } from '#src/components/fourthline/embedded-websdk';
import { EmbeddedWebsdk } from './embedded-websdk';
import styles from './fourthline.strict-module.css';
import { browser, isMobile, isWebView, os } from '#src/utils/device';

interface BaseFourthlineProps {
  fourthlineRedirectUrl: string;
  isKycRequired: boolean;
  isQesRequired: boolean;
  legalCountry: string;
  mode?: string;
  onBack?: () => void;
}

interface EmbeddedWebSDKProps extends BaseFourthlineProps {
  embeddedWebSdkProps: Omit<EmbeddedWebsdkProps, 'visible'>;
  onSendSMS?: never;
  onRegenerateLink?: never;
}

interface LegacyFlowProps extends BaseFourthlineProps {
  embeddedWebSdkProps?: never;
  onSendSMS: () => void;
  onRegenerateLink: () => void;
}

export type FourthlineProps = EmbeddedWebSDKProps | LegacyFlowProps;

function Wrapper({ children }: PropsWithChildren): ReactNode {
  return <div className={styles.wrapper}>{children}</div>;
}

export function Fourthline({
  mode = 'verification',
  onBack,
  isKycRequired,
  isQesRequired,
  fourthlineRedirectUrl,
  legalCountry,
  embeddedWebSdkProps,
  onSendSMS,
  onRegenerateLink,
}: FourthlineProps): ReactNode {
  const [displayReassurance, setDisplayReassurance] = useState(true);
  const segment = useSegment();

  const isAndroid = os.name === 'Android';
  const isIOS = os.name === 'iOS';
  const isAndroidAndIsUnsupported =
    isAndroid && isUnsupportedAndroid(browser.name, browser.version);
  const isIosAndIsUnsupported =
    isIOS && isUnsupportedIOS(browser.name, browser.version, os.version);

  const onReassuranceContinue = (): void => {
    if (embeddedWebSdkProps) {
      segment.track(`${mode}_fourthline_started_websdk`, {
        domain: window.location.hostname,
        isMobile,
      });
    }

    if (isWebView) {
      simulateLinkClick({
        href: fourthlineRedirectUrl,
        target: '_blank',
        rel: 'noopener noreferrer',
      });
      return;
    }

    if (isMobile) {
      window.location.replace(fourthlineRedirectUrl);
      return;
    }

    setDisplayReassurance(false);
  };

  const turnOnDisplayReassurance = (): void => {
    setDisplayReassurance(true);
  };

  if ((isIosAndIsUnsupported || isAndroidAndIsUnsupported) && !isWebView) {
    return (
      <Wrapper>
        <Unsupported fourthlineRedirectUrl={fourthlineRedirectUrl} mode={mode} />
      </Wrapper>
    );
  }

  if (isWebView && !displayReassurance && !embeddedWebSdkProps) {
    return (
      <Wrapper>
        <Pending
          fourthlineRedirectUrl={fourthlineRedirectUrl}
          onBack={turnOnDisplayReassurance}
          onRegenerateLink={onRegenerateLink}
        />
      </Wrapper>
    );
  }

  if (!isMobile && !displayReassurance && !embeddedWebSdkProps) {
    return (
      <Wrapper>
        <LinkToMobile
          fourthlineRedirectUrl={fourthlineRedirectUrl}
          isKycRequired={isKycRequired}
          isQesRequired={isQesRequired}
          mode={mode}
          onBack={turnOnDisplayReassurance}
          onRegenerateLink={onRegenerateLink}
          onSendSMS={onSendSMS}
        />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      {displayReassurance ? (
        <Reassurance
          isKycRequired={isKycRequired}
          isQesRequired={isQesRequired}
          legalCountry={legalCountry}
          mode={mode}
          onBack={onBack}
          onContinue={onReassuranceContinue}
        />
      ) : null}
      {embeddedWebSdkProps ? (
        <EmbeddedWebsdk visible={!displayReassurance} {...embeddedWebSdkProps} />
      ) : null}
    </Wrapper>
  );
}
