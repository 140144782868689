import type { RadioGroupProps as AriaRadioGroupProps } from 'react-aria-components';
import { Label, RadioGroup as AriaRadioGroup, FieldError, Button } from 'react-aria-components';
import type { ReactElement, ReactNode } from 'react';
import cx from 'clsx';
import { WarningSign, InfoIcon } from '../../assets/icons';
import { Tooltip } from '../tooltip';
import styles from './radio-group.strict-module.css';

interface RadioGroupProps extends AriaRadioGroupProps {
  label?: string;
  errorMessage?: string;
  defaultValue?: string;
  children?: ReactNode;
  tooltipText?: string;
}

export function RadioGroup({
  label,
  errorMessage,
  children,
  className,
  tooltipText,
  ...props
}: RadioGroupProps): ReactElement {
  return (
    <AriaRadioGroup className={cx(styles.wrapper, className)} {...props} data-test-radio-group>
      {label ? (
        <Label className={cx(styles['group-label'], 'mb-24 title-4')}>
          {label}
          {tooltipText ? (
            <Tooltip label={tooltipText} placement="top">
              <Button aria-label="tooltip" className={cx(styles.tooltip, 'ml-8')}>
                <InfoIcon />
              </Button>
            </Tooltip>
          ) : null}
        </Label>
      ) : null}
      {children}
      {errorMessage ? (
        <FieldError className={styles.error}>
          <WarningSign className="mr-8" />
          {errorMessage}
        </FieldError>
      ) : null}
    </AriaRadioGroup>
  );
}
