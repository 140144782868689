import type { ReactElement, ReactNode } from 'react';
import type {
  CheckboxGroupProps as AriaCheckboxGrougProps} from 'react-aria-components';
import {
  CheckboxGroup as AriaCheckboxGroup,
} from 'react-aria-components';
import cx from 'clsx';
import { WarningSign } from '../../assets/icons';
import { Error } from '../form-elements';
import styles from './checkbox-group.strict-module.css';

interface CheckboxGroupProps extends Omit<AriaCheckboxGrougProps, 'children'> {
  children?: ReactNode;
  errorMessage?: string;
}

export function CheckboxGroup({
  errorMessage,
  children,
  className,
  ...props
}: CheckboxGroupProps): ReactElement {
  return (
    <AriaCheckboxGroup
      className={cx(
        styles['checkbox-group'],
        Boolean(errorMessage) && styles['with-error-message'],
        className
      )}
      {...props}
    >
      {children}
      {errorMessage ? (
        <Error className={styles.error}>
          <WarningSign className="mr-8" />
          {errorMessage}
        </Error>
      ) : null}
    </AriaCheckboxGroup>
  );
}
