import type { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import cx from 'clsx';
import { Button, CheckboxGroup, Checkbox, Form } from '@repo/design-system-kit';
import { useSegment } from '@repo/poly-hooks/segment';
import { BackButtonSimple } from '#src/components/shared/back-button-simple';
import { Sign, Identity, Check } from '#src/components/svgs';
import styles from './reassurance.strict-module.css';

export interface ReassuranceProps {
  isKycRequired: boolean;
  isQesRequired: boolean;
  mode: string;
  onContinue: () => void;
  legalCountry: string;
  onBack?: () => void;
}

export function Reassurance({
  isKycRequired,
  isQesRequired,
  legalCountry,
  onContinue,
  onBack,
  mode,
}: ReassuranceProps): ReactNode {
  const segment = useSegment();
  const intl = useIntl();
  const { titleId, subtitleId } = (() => {
    if (isKycRequired && isQesRequired) {
      return {
        titleId: 'fourthline.reassurance.kyc-and-qes.title',
        subtitleId: 'fourthline.reassurance.kyc-and-qes.subtitle',
      };
    }

    if (isKycRequired) {
      return {
        titleId: 'fourthline.reassurance.kyc.title',
        subtitleId: 'fourthline.reassurance.kyc.subtitle',
      };
    }

    return {
      titleId: 'fourthline.reassurance.qes.title',
      subtitleId: 'fourthline.reassurance.qes.subtitle',
    };
  })();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    onContinue();
  };

  const bulletPointsList = (() => {
    const list = [
      intl.formatMessage({ id: 'fourthline.reassurance.requirement.network' }),
      intl.formatMessage({ id: 'fourthline.reassurance.requirement.phone' }),
    ];

    if (isKycRequired) {
      return [
        intl.formatMessage({ id: 'fourthline.reassurance.requirement.id' }, { legalCountry }),
        ...list,
      ];
    }

    return list;
  })();

  const Illustration = !isKycRequired && isQesRequired ? Sign : Identity;

  return (
    <div data-test-reassurance>
      <header className={styles.header}>
        <div className="mb-32" data-test-icon>
          <Illustration height={116} width={116} />
        </div>
        <h2 className="title-2">
          <FormattedMessage id={titleId} />
        </h2>
        <p
          className="body-2"
          dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: subtitleId }) }}
          data-test-agreement-subtitle
          onClick={() => {
            segment.track(`${mode}_fourthline_website_link_clicked`);
          }}
        />
      </header>
      <ul className={cx('mb-24', styles.bulletPointsList)} data-test-bulletpoints>
        {bulletPointsList.map(message => (
          <li key={message}>
            <Check />
            <span className="body-2">{message}</span>
          </li>
        ))}
      </ul>
      {/* there's an ongoing issue with checkbox validation is not removing the error when it should but a fix is ready here: https://github.com/adobe/react-spectrum/pull/6079 */}
      <Form onSubmit={handleSubmit}>
        <CheckboxGroup
          className="mb-32"
          errorMessage={intl.formatMessage({ id: 'fourthline.reassurance.terms.error' })}
          isRequired
        >
          <Checkbox data-test-agreement-checkbox>
            <span
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({ id: 'fourthline.reassurance.terms.label' }),
              }}
              data-testid="label"
            />
          </Checkbox>
        </CheckboxGroup>
        <Button data-test-agreement-submit stretch type="submit">
          <FormattedMessage id="btn.continue" />
        </Button>
      </Form>
      {onBack ? (
        <div className={styles.backButtonContainer}>
          <BackButtonSimple data-test-go-back onPress={onBack} />
        </div>
      ) : null}
    </div>
  );
}
